import { Injectable } from '@angular/core';
import { SafeResourceUrl, DomSanitizer, Meta, Title } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SeoOptimizerService {
  defaultDesc: string = "FOR OVER TWO DECADES, CAIRO JAZZ CLUB HAS STOOD THE TEST OF TIME AS CAIRO’S ULTIMATE LIVE MUSIC HUB. WHETHER YOU'RE LOOKING FOR LIVE MUSIC, A NIGHT OUT, DELICIOUS COCKTAILS OR A BITE TO EAT, WE'VE GOT IT ALL RIGHT HERE.";
  defaultImage: string = environment.frontUrl + "assets/imgs/cjc_cat_white.webp";

  constructor(private meta: Meta, private http: HttpClient) { }



  removeEvent() {
    this.updateDefaultTags();
  }

  addDefaultTags() {
    this.meta.addTags([
      { name: 'keywords', content: 'bar,jazz,events,night life,Cairo jazz club, live music, DJ music, night club, restaurant, cocktails, entertainment, jazz music, Cairo nightlife, events, parties, dining, bar, venue, music scene, local bands, performances, food and drink, nightlife hotspots, dance floor' },
      { property: 'og:type', content: 'website' },
      { name: 'googlebot', content: 'index,follow' },
      { name: 'robots', content: 'index,follow' },
      { charset: 'UTF-8' },
      { name: "description", content: this.defaultDesc },
      { property: "og:description", content: this.defaultDesc },
      { property: "og:image", content: this.defaultImage },
      { name: "image", content: this.defaultImage },
    ]);
  }

  updateDefaultTags() {
    this.updateMainTags(this.defaultDesc, this.defaultImage);
  }

  updateMainTags(description, image) {
    this.meta.updateTag({ name: 'description', content: description });
    this.meta.updateTag({ property: 'og:description', content: description });
    this.meta.updateTag({ name: 'image', content: image });
    this.meta.updateTag({ property: 'og:image', content: image });
  }

}
