import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class galleryService {
  photoSubject =new BehaviorSubject<any>('');
  videoSubject =new BehaviorSubject<any>('');
  constructor(private http: HttpClient) { }

  getPhoto() : Observable <any> {
    return this.photoSubject.asObservable();     
  }
  getvideo() : Observable <any> {
    return this.videoSubject.asObservable();     
  }

  selectPhoto(product:any){   
    this.photoSubject.next(product);
  }
  selectvideo(product:any){   
    this.videoSubject.next(product);
  }
  getgalleries(){
    return this.http.get<any>(environment.localUrl + 'galleries/list');
  }
  getgallery(id){
    //return this.http.get<any>(environment.localUrl + `galleries/list?pagenumber=${id}`);
    return this.http.get<any>(environment.localUrl + `website/gallery?pagenumber=${id}`);
  }
  // getgalleries(id){
  //   return this.http.get<any>(environment.localUrl + `galleries/list?pagenumber=${id}`);
  // }
  getgallerydetails(id){
    return this.http.get<any>(environment.localUrl + `website/gallery/details?id=${id}`);
  }
  filteredGalleries(pageNumber , type){
    return this.http.get<any>(environment.localUrl + `galleries/filter?pagenumber=${pageNumber}&filter=${type}`);
  }

  

}